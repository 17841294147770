@import url('https://fonts.googleapis.com/css2?family=Pretendard:wght@400&display=swap');


.App {
  position: relative; /* 상대 위치 지정 */
  text-align: center;
  background-color: #111111;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  
}

.App::before,
.App::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #727272;
  opacity: 0.2;
}

.App::before {
  left: 25%;
}

.App::after {
  left: 75%;
}

/* 추가적인 세로선을 위한 새로운 CSS */
.App {
  display: flex;
  justify-content: space-between;
}

.mouse-circle {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: yellow;
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%); /* Center the circle at the cursor */
  mix-blend-mode: multiply;
  z-index: 998;
}

.vertical-line {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%; /* 중앙 선 */
  width: 1px;
  background-color: #727272;
  opacity: 0.2;
  z-index: 1;
}

.header {
  width: 100%;
  height: 70px;
  border-bottom: 1px solid #727272;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #111;
  color: white;
  display: flex;
  align-items: center;
  justify-content: space-between; /* 수정됨: 중앙 정렬에서 양 끝 정렬로 변경 */
  padding: 0 60px;
  box-sizing: border-box;
  z-index: 1001;
}

.header-logo {
  text-align: left;
  position: relative;
}

.header-content img:hover {
  transform: scale(1.2);
  transition: transform 2s;
  cursor: pointer;
}


.header-content {
  width: 180px;
  height: 30px;
  background-color: #111; /* 배경색, 필요에 따라 변경 가능 */
  color: #fff; /* 텍스트 색상, 필요에 따라 변경 가능 */
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.main-visual {
  width: 100%;
  height: 937px;
  background-color: transparent; /* 버튼 배경색을 투명으로 설정 */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; /* 텍스트 위치 조정을 위해 relative 설정 */
}

.svg-container {
  position:absolute;
  top:120px;
  right:120px;
}

.rotate-circle {
  animation: rotateCircle 30s linear infinite;
}

@keyframes rotateCircle {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.rotate-circle text {
  font-family: 'Pretendard', sans-serif; /* 폰트를 프리텐다드로 설정 */
}

.main-visual-text {
  font-family: 'Pretendard', sans-serif;
  font-size: 60px;
  position: absolute;
  left:0;
  text-align: left;
  padding: 0 60px;
  font-weight: bold; /* 폰트 굵기 설정 */
}

.blinking-underscore {
  animation: blink 1.5s step-start infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

.portfolio-container {
  display: flex;
  justify-content: flex-end;
  width: 100%; /* 필요한 경우 부모 요소의 크기에 맞게 조정 */
  z-index: 2;
  margin-bottom: 200px;
}

.text-box {
  font-family: 'Pretendard', sans-serif;
  width: 340px;
  height: 340px;
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: space-around; /* 이를 통해 내부 텍스트가 고르게 분포됩니다 */
}

.fixed {
  position: fixed;
  top: 100px; /* 원하는 초기 위치 */
  left: 60px; /* 원하는 초기 위치 */
  width: 100%;
  z-index: 1000; /* 다른 요소 위에 나타나도록 설정 */
}

.project-text {
  left: 60px; 
  font-size: 42px;
  text-align: left; 
  line-height: 1.3; 
  
}

.view-all {
  display: flex;
  align-items: center;
}

.view-all span {
  font-size: 18px;
  font-weight:500;
  
}

.plus-button {
  background-color: transparent; /* 버튼 배경색을 투명으로 설정 */
  color: #727272; /* 버튼 텍스트 색상을 흰색으로 설정 */
  border: none; /* 기본 테두리 제거 */
  font-size: 24px; /* 버튼 텍스트 크기 조정 */
  cursor: pointer; /* 버튼에 커서 포인터 추가 */
  padding: 0 8px; /* 버튼의 패딩 추가 */
  align-items: center; /* 버튼 내부의 요소들을 세로로 중앙 정렬 */
  transition: transform 0.5s ease; /* transform 속성에 대한 전환 효과 추가 */
}

.plus-button:hover {
  transform: rotate(-90deg); /* 마우스를 올렸을 때 360도 회전 */
}

.portfolio-images li:nth-child(1),
.portfolio-images li:nth-child(3),
.portfolio-images li:nth-child(5),
.portfolio-images li:nth-child(7) {
  margin-top: 40px; /* 첫 번째 행의 사각형 상단에 40px의 여백 추가 */
}

.portfolio-images {
  display: grid;
  grid-template-columns: repeat(2, 699px); /* 2열 */
  grid-template-rows: repeat(4, 903px); /* 4행 */
  gap: 40px; /* 이미지 사이의 간격 (원하는 대로 조정 가능) */
  list-style: none;
  padding: 0;
  margin-right: 60px;
}


.portfolio-images li {
  width: 699px;
  height: 903px;
  background-color:#222;
  overflow: hidden;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.portfolio-images img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* 이미지가 영역을 채우도록 조정 */
  transition: transform 0.3s ease-in-out;
  
}

.portfolio-images li::before {
  content: 'ㅇㅇㅇ';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
}

.portfolio-images li:hover {
  transform: scale(1.05);
  opacity: 1.3;
}

.portfolio-images li:hover::before {
  opacity: 1;
}

.portfolio-images li:hover img {
  transform: scale(1.05);
}

/* 모바일 스타일 */
@media screen and (max-width: 375px) and (max-height: 812px) {
  .main-visual-text {
    display: block;
  }

  .main-visual,
  .portfolio-container,
  .vertical-line,
  .App::before,
  .App::after,
  .contact-text,
  .design-option,
  .footer {
    display: none;
  }

  /* 필요한 경우 추가적인 모바일 스타일 */
}

.pc-optimized-message {
  position: fixed;
  left: 60px;
  bottom: 120px;
  background-color: rgba(0, 0, 0, 0.3); /* 반투명 배경 */
  color: #727272;
  padding: 10px 20px;
  font-size: 14px;
  z-index: 1000; /* 다른 요소 위에 표시되도록 */
}