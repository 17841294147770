@import url('https://fonts.googleapis.com/css2?family=Pretendard:wght@400&display=swap');

body {
  font-family: 'Pretendard', sans-serif;
}

.footer {
  display: flex; /* Flexbox 사용 */
  flex-direction: column; /* Flex 방향을 컬럼으로 설정 */
  justify-content: center; /* 수직 가운데 정렬 */
  align-items: flex-start; /* 수평 왼쪽 정렬 */
  position: relative;
  width: 100%;
  height: 531px; /* 최소 높이 설정, 필요에 따라 조정 */
  text-align: left; /* 텍스트 왼쪽 정렬 */
  background-color: #111;
  z-index: 1;
}


.footer-line {
  position: absolute;
  left: 0;
  right: 0;
  width: 1800px; /* 선의 너비를 1800px로 설정 */
  height: 1px; /* 선의 높이를 1px로 설정 */
  background-color: white; /* 선의 색상을 흰색으로 설정 */
  margin: 0 auto; /* 좌우 마진을 자동으로 설정하여 중앙 정렬 */
}

.footer-line.top {
  top: 0; /* 최상단에 위치 */
}

.footer-line.bottom {
  bottom: 0; /* 최하단에 위치 */
}

.footer-bottom {
  width: 100%; /* 너비를 100%로 설정 */
  text-align: center; /* 텍스트 중앙 정렬 */
  padding: 20px 0; /* 상하 패딩 설정 */
  background-color: #111; /* 배경색 설정 */
  color: #727272; /* 텍스트 색상 설정 */
  font-size: 16px;
  background-color: #111;
  z-index: 1;
}

.footer-container {
  display: flex; /* Flex 컨테이너 설정 */
  justify-content: space-between; /* 자식 요소들을 왼쪽과 오른쪽 끝으로 정렬 */
  width: 100%; /* footer-container의 너비를 100%로 설정 */
}

.footer-content {
  font-family: 'Pretendard', sans-serif; /* 폰트 패밀리 설정 */
  font-size: 60px; /* 폰트 사이즈 설정 */
  display: flex; /* Flexbox 사용 */
  justify-content: flex-start; /* 왼쪽 정렬 */
  padding: 0 60px;
  margin-bottom: 60px;
}

.footer-service {
  font-family: 'Pretendard', sans-serif; /* 폰트 패밀리 설정 */
  font-size: 20px; /* 폰트 사이즈 설정 */
  display: flex; /* Flexbox 사용 */
  justify-content: flex-start; /* 왼쪽 정렬 */
  padding: 0 67px;
  font-weight: 300;
  width: 930px;
}

.plus-button {
  background-color: transparent; /* 버튼 배경색을 투명으로 설정 */
  color: #fff; /* 버튼 텍스트 색상을 흰색으로 설정 */
  border: none; /* 기본 테두리 제거 */
  font-size: 26px; /* 버튼 텍스트 크기 조정 */
  cursor: pointer; /* 버튼에 커서 포인터 추가 */
  padding: 0 8px; /* 버튼의 패딩 추가 */
  transition: transform 0.5s ease; /* transform 속성에 대한 전환 효과 추가 */
}

.plus-button:hover {
  transform: rotate(-90deg); /* 마우스를 올렸을 때 360도 회전 */
}

.footer-items {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top:-100px;
  width: 930px; /* 지정된 너비 */
  justify-content: flex-end; /* 우측 끝에 배치 */
  padding: 0 60px;
  font-weight: medium;
  
}

.footer-items li {
  flex: 1; /* 각 항목을 동일한 비율로 나누기 */
  width: 25%; /* 각 항목의 너비를 25%로 설정 */
  display: flex;
  align-items: center; /* 수직 중앙 정렬 */
  text-align: left; /* 텍스트 좌측 정렬 */
  padding-left: 10px; /* 텍스트와 좌측 경계 사이의 간격 */
  font-size:16px;
}
