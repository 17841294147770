@import url('https://fonts.googleapis.com/css2?family=Pretendard:wght@400&display=swap');

.menu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  color: white;
  display: flex;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 2s ease, visibility 0s ease 2s;
  z-index: 1000;
}



.menu.open {
  opacity: 1;
  visibility: visible;
  background-color: #111;
  transition: opacity 1s ease, visibility 0s ease 0s;
  
}

.menu.closed {
  opacity: 0;
  visibility: hidden;
  transition: opacity 1s ease, visibility 0s ease 1s;
}

.menu ul {
  list-style: none;
  padding: 0;
}

.menu-link {
  font-size: 72px;
  color:#fff;
  text-decoration: none;
}

.menu li {
  margin: 20px 0;
  font-size: 72px;
  cursor: pointer;
  font-weight: 600;
  text-align: left; /* 좌측 정렬 */
  padding-left: 60px; /* 텍스트가 좌측에서 60px 떨어지도록 설정 */
}

.side-panel {
  display: flex;
  flex-direction: column; /* 수직 정렬을 위해 column 방향 설정 */
  justify-content: center; /* 수직 중앙 정렬 */
  width: 33%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
  border-left: 1px solid #727272; /* 좌측에 1px짜리 세로 선 추가 */
  font-size: 16px;
}

.bottom-area {
  width: 100%;
  height: 120px;
  position: absolute;
  bottom: 0;
  display: flex;
  border-top: 1px solid #727272; 
}

.left-half, .middle-half, .right-half {
  flex: 1;
  display: flex;
  justify-content: center; /* 가로축 기준 중앙 정렬 */
  align-items: center; /* 세로축 기준 중앙 정렬 */
  text-align: center; /* 텍스트 중앙 정렬 */
  font-size: 16px;
  font-weight: 600;
}

.left-half, .middle-half {
  border-right: 1px solid #727272; 
}
.left-half::after, middle-half::before, .right-half::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1px;
  background-color: #727272;
}

.left-half::after {
  right: 0%; /* Position the line in the middle of the .bottom-area */
}

.right-half::before {
  left: 0%; /* Position the line in the middle of the .bottom-area */
}

.contact-us {
  align-items: center; /* 세로축 기준 중앙 정렬 */
  margin-left: 54px; /* 좌측에서 54px 떨어지도록 설정 */
  text-align: left; /* 좌측 정렬 */
  padding: 10px 0; /* 상하 여백 추가 */
  color: #fff;
}

.contact-us p {
  margin: 0; /* p 태그의 기본 마진을 제거합니다. */
  padding: 2px 0; /* p 태그 사이의 간격을 조정합니다. */
  font-weight: 600;
  margin-bottom: 6px;
}

.contact-us .info {
  color: #50504D; /* email 클래스에 대한 폰트 색상을 #777로 설정 */
}

.menu-item {
  position: relative;
}

.project-count {
  position: absolute;
  top: 0px;
  right: -15px;
  width: 66px;
  height: 39px;
  background-color: #EBC166;
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24pt; /* 폰트 크기를 26pt로 설정 */
  color:#111;

  transform: translateY(-1px); /* 1px 위로 이동 */
}

.project-count span {
  position: relative;
  top: -1px; /* 숫자만 1px 위로 올리는 위치 조정 */
}
