@import url('https://fonts.googleapis.com/css2?family=Pretendard:wght@400&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html, body {
    width: 100%;
    height: 100%;
  }
  
  .project-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    position: relative;
    color: white;
    background-color: #111; /* Assuming a dark background for contrast with the white text and line */
    margin-bottom: 200px;
  }
  
  .project h1 {
    margin: 0;
    font-size: 80px;
    text-align: left;
    font-weight: 600;
    letter-spacing: -2px;
  }
  .project h1 .highlight {
    color: #EBC166; /* 포트폴리오 텍스트의 색상을 변경 */
  }
  
  .project-line {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1800px;
    height: 1px;
    background-color: white;
    transform: translateX(-50%);
  }
  
  .rectangle-container {
    width: 1800px; /* 사각형들의 합계 너비 */
    display: flex;
    justify-content: center;
  }
  
  .rectangle {
    width: 556px; /* 사각형 너비 (1800px / 3 - 30px * 2) */
    height: 743px; /* 사각형 높이 */
    background-color: #222; /* 사각형 색상 */
    margin: 0 30px; /* 사각형 간격 */
    list-style-type: none; /* 점 제거 */
  }
  
  /* 가운데 사각형을 위로 30px 올리기 */
  .rectangle:nth-child(2) {
    margin-top: -30px;
  }

  .rectangle img {
    transition: transform 0.3s ease-in-out; /* Smooth transition for image scaling */
    width: 100%;
    height: auto;
  }

  
  .rectangle:hover img {
    transform: scale(1.05); /* Scale image on hover */
  }
  
  .rectangle:hover::before {
    opacity: 0.5; /* Show text on hover */
  }

  .rectangle-container2 {
    width: 1800px; /* 사각형들의 합계 너비 */
    display: flex;
    justify-content: center;
  }
  
  .rectangle2 {
    width: 556px; /* 사각형 너비 (1800px / 3 - 30px * 2) */
    height: 743px; /* 사각형 높이 */
    background-color: #222; /* 사각형 색상 */
    margin: 0 30px; /* 사각형 간격 */
    list-style-type: none; /* 점 제거 */
  }
  
  /* 가운데 사각형을 위로 30px 올리기 */
  .rectangle2:nth-child(2) {
    margin-top: -30px;
  }

  .rectangle2 img {
    transition: transform 0.3s ease-in-out; /* Smooth transition for image scaling */
    width: 100%;
    height: auto;
  }

  .rectangle2::before {
    content: 'ㅇㅇㅇ';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 24px;
    opacity: 0; /* Initially hidden */
    transition: opacity 0.3s ease-in-out; /* Smooth transition for opacity */
  }
  
  .rectangle2:hover img {
    transform: scale(1.05); /* Scale image on hover */
  }
  
  .rectangle2:hover::before {
    opacity: 0.5; /* Show text on hover */
  }

  .more-button {
    width: 100px;
    height: 100px;
    color: #000;
    font-size: 48px;
    line-height: 1;
    background: none;
    border: none;
    cursor: pointer;
    position: relative;
  }


  .more-button::before, .more-button::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100px;
    height: 4px;
    background-color: #EBC166;
    transform-origin: center;
    transition: transform 0.3s ease-in-out;
  }
  
  .more-button::before {
    transform: translate(-50%, -50%)
  }
  
  .more-button::after {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  .more-button:hover::before {
    transform: translate(-50%, -50%) rotate(-90deg);
  }
  
  .more-button:hover::after {
    transform: translate(-50%, -50%) rotate(-180deg); 
  }
  
  .wrapper {
    position: relative; /* wrapper에 상대적인 위치 지정 */
  }
  