@import url('https://fonts.googleapis.com/css2?family=Pretendard:wght@400&display=swap');

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  
  html, body {
    width: 100%;
    height: 100%;
  }
  
  .contact-container {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start; /* 좌측 정렬 */
    padding-left: 60px;
    position: relative;
    color: white;
    background-color: #111; /* Assuming a dark background for contrast with the white text and line */
    margin-bottom: 65px;
  }
  
  .project h1 {
    margin: 0;
    font-size: 80px;
    text-align: left;
    font-weight: 600;
    letter-spacing: -2px;
  }
  .project h1 .highlight {
    color: #EBC166; /* 포트폴리오 텍스트의 색상을 변경 */
  }
  
  .project-line {
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 1800px;
    height: 1px;
    background-color: white;
    transform: translateX(-50%);
  }

.App::before,
.App::after {
  display: none !important;
}

.contact-text-container {
  width: 100%;
  height: 2400px;
  display: flex;
  justify-content: flex-start; /* 왼쪽 정렬 */
  align-items: flex-start; /* 세로로 위쪽 정렬 */
  padding-left: 60px; /* 왼쪽으로 60px 이동 */
  box-sizing: border-box;
}

.contact-text {
  width: 445px;
  height: 300px;
  text-align: left; /* 텍스트를 좌측 정렬 */
  letter-spacing: -2px;
}


.additional-box {
  width: 558px;
  height: 221px;
  margin-left: 420px;
  background-color: #111;
  text-align: left;
}

.square-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
}

.square {
  width: 130px; /* 각 사각형의 너비를 조절해주세요 */
  height: 54px;
  background-color: #111; /* 사각형의 배경색 */
  border: 1px solid #707070; /* 사각형의 테두리 스타일 */
  margin-right: 20px; /* 각 사각형 간의 간격 조절 */
  display: flex; /* 텍스트를 가운데 정렬하기 위해 flex를 사용합니다 */
  justify-content: center; /* 수평 가운데 정렬 */
  align-items: center; /* 수직 가운데 정렬 */
}

.square-container {
  font-size: 15px; /* 텍스트의 폰트 크기 */
  color: #fff; /* 텍스트의 색상 */
}

.square-container .square {
  color: #999;
  font-size: 15px;
  transition: color 0.3s, font-weight 0.3s; /* 텍스트 색상 및 글꼴 두껍게 전환 효과 */
}

.square-container .square:hover {
  font-weight: bold; /* 호버 시 글꼴 두껍게 */

}

.square-container .square.clicked {
  color: #111; /* 클릭 시 텍스트 색상 변경 */
  background-color: #fff;
  font-weight: bold; /* 호버 시 글꼴 두껍게 */
  border:#fff;
}

.triangle1 {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 11px solid white; /* 삼각형의 색상 및 크기 설정 */
  opacity: 50%;
  animation: moveUpDown1 2s infinite alternate; /* 위 아래로 왔다갔다하는 애니메이션 */
}

@keyframes moveUpDown1 {
  0% {
    transform: translateY(0); /* 초기 위치 */
  }
  50% {
    transform: translateY(10px); /* 중간 위치 */
  }
  100% {
    transform: translateY(0); /* 최종 위치 */
  }
}

.triangle2 {
  width: 0;
  height: 0;
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 11px solid white; /* 삼각형의 색상 및 크기 설정 */
  animation: moveUpDown1 2s infinite alternate; /* 위 아래로 왔다갔다하는 애니메이션 */
}

@keyframes moveUpDown2 {
  0% {
    transform: translateY(0); /* 초기 위치 */
  }
  50% {
    transform: translateY(10px); /* 중간 위치 */
  }
  100% {
    transform: translateY(0); /* 최종 위치 */
  }
}
