@import url('https://fonts.googleapis.com/css2?family=Pretendard:wght@400&display=swap');


/* src/About.css */
.about {
  position: relative;
  padding-left: 130px;
  font-size: 26px;
  font-family: "Pretendard", sans-serif;
  line-height: 1.5;
  text-align: left;
  width: calc(100% - 260px);
  margin-top: 105px;
  margin-bottom: 200px;
}

.about-title {
  font-size: 16px;
  color: #555555;
  margin-bottom: -20px;
  position: relative;
  font-weight: bold;
  display: inline-block;
}


.about-title::before {
  content: "";
  position: absolute;
  left: -46px;
  top: 50%;
  transform: translateY(-50%);
  width: 31px;
  height: 1px;
  background-color: #555555;
}

.about h1 {
  font-weight: 400;
  font-size:40px;
  margin-top: 0px;
  white-space: pre-line;
  vertical-align: top;
}

.about-rectangle {
  
  width: 1410px;
  height: 730px;
  background-color: #222;
  margin-top: 40px;
}

.unique-text {
  font-size: 50px;
  font-weight: bold;
  margin-top: 20px;
  margin-left: 200px;
  text-align: left;
}

.small-rectangle {
  width: 152px;
  height: 46px;
  background-color: #ebc166;
  margin-top: -30px;
  margin-left: 240px;
}

.additional-text {
  font-size: 50px;
  font-weight: bold;
  margin-top: 70px;
  margin-left: 200px;
  text-align: left;
}

.final-text {
  font-size: 16pt;
  font-weight: regular;
  margin-top: 50px;
  margin-left: 200px;
  text-align: left;
  line-height: 1.4;
  letter-spacing: -0.5px;
}

.my-service {
  font-size: 180px;
  font-weight: bold;
  margin-top: 100px;
  text-align: left;
}

.circle-container {
  display: flex;
  padding-left: 200px;
  position: relative; /* 추가 */
  margin-bottom: 200px; /* .thoughts-container와의 간격을 설정합니다. */
}

.korean,
.english {
  margin: 0;
  padding: 0;
}

.circle {
  width: 318px;
  height: 318px;
  border: 1px solid #ebc166;
  border-radius: 50%;
  background-color: #111;
  margin: 0 10px; /* 가로 간격을 20px로 맞춤 */
  display: flex;
  justify-content: center; /* 텍스트를 가운데 정렬 */
  align-items: center; /* 텍스트를 가운데 정렬 */
}

.korean {
  color: #ebc166; /* 한글 텍스트 색상 변경 */
  font-size: 42px; /* 한글 텍스트 크기 변경 */
  font-weight: 600;
}

.english {
  color: white; /* 영문 텍스트 색상 변경 */
  font-size: 20px; /* 영문 텍스트 크기 변경 */
}

.text-group {
  text-align: center;
}

.thoughts-container {
  position: absolute;
  bottom: 0;
  margin-bottom: -200px; /* .circle-container와의 간격을 설정합니다. */
}

.thoughts-text {
  font-size: 50px;
  color: #ffffff;
  font-weight: bold;
  line-height: 1.3;
  margin-bottom: 80px;
}

.additional-thoughts-container {
  margin-top:200px;
  display: flex; /* 가로로 정렬 */
  align-items: center; /* 세로 가운데 정렬 */
}

.additional-thoughts-text {
  font-size: 22px;
  line-height: 1.7;
  letter-spacing: -0.5px;
}

.work-text {
  margin-right: 420px; /* WORK. 오른쪽 간격을 260px로 설정 */
  color: #fff;
  font-size: 50px;
  font-weight: bold;
}

.special {
  color:#ebc166;
}

.experience-text {
  margin-right: 260px; /* WORK. 오른쪽 간격을 260px로 설정 */
  right: 280px; /* 우측으로 280px 이동 */
  top: 0; /* 상단 정렬 */
  font-size: 50px; /* 텍스트 크기 */
  color: #fff; /* 텍스트 색상 */
  font-weight: bold; /* 텍스트 굵게 */
}

.introduce {
  margin-top:20px;
  display: flex;
  flex-direction: row;
  font-size: 22px;
  color:#f6f6f6;
  line-height: 2.0;
}

.work{
  width: 420px;
  height: 800px;
  margin-right: 150px;
}

.experience-description {
  line-height: 2.0;
}